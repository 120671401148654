import useGetUserInfo from "hooks/useGetUserInfo";
import styles from "assets/styles.module.css";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import BottomTab from "./BottomTab";
import Header from "./Header";
import Drawer from "./Drawer";

/**
 * RequireAuth est un composant de navigation qui permet de protéger les routes qui nécessitent une authentification.
 * Si l'utilisateur est authentifié, le composant rend le contenu de la page, sinon il redirige l'utilisateur vers la page de connexion.
 */
function RequireAuth() {
  const { userId, schoolId, connection } = useGetUserInfo();

  const location = useLocation();

  return userId ? (
    <>
      <Header />
      <div className={styles.appelContainer}>
        <Outlet />
      </div>
      {schoolId && connection?.access && <BottomTab />}
      <Drawer />
    </>
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
}

export default RequireAuth;
