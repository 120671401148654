import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API } from "routes/api";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API,
  }),
  refetchOnReconnect: true,
  keepUnusedDataFor: 86400,
  reducerPath: "genimiApi",
  endpoints: () => ({}),
});

export const ApiMananger = baseApi.enhanceEndpoints({
  addTagTypes: [
    "Coachs",
    "Fiches",
    "Users",
    "Eleves",
    "Appels",
    "comprehensions",
    "Paiements",
    "Evaluations",
    "Niveaux",
  ],
});

// const _apiWithTaggedEndpoint = baseApiWithTag.injectEndpoints({
//   endpoints: build => ({
//     test: build.query({
//        query: () => "test",
//        providesTags: ['Foo']
//     }),
//     test2: build.query({
//        query: () => "test",
//        // @ts-expect-error
//        providesTags: ['Bar']
//     })
//   })
// })
