import styles from "assets/styles.module.css";
import React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { FaList, FaUsers } from "react-icons/fa";
import Students from "./Students";
import AppelList from "./AppelList";
import { useDispatch, useSelector } from "react-redux";
// import {
//   addAppelOffline,
//   clearAppelOffline,
//   getAppelsOfflineData,
// } from "redux/features/AppelSlice";
import { useGetElevesQuery } from "redux/features/eleveApi";
import useGetUserInfo from "hooks/useGetUserInfo";
import {
  useAddAppelMutation,
  useGetCoachAppelsQuery,
  useUpdateAppelMutation,
} from "redux/features/appelApi";
import { useGetCoachQuery } from "redux/features/coachApi";
import { useNavigate } from "react-router-dom";
import useConnection from "hooks/useConnection";
// import Tab from "components/Tab";
import TabLink from "components/TabLink";
import { bgColorPrimary } from "constants";
import {
  addAppelOffline,
  clearAppelOffline,
  getAppelsOfflineData,
} from "redux/features/userSlice";

function Appel() {
  const { userId, schoolId, csrfToken } = useGetUserInfo();
  const { isOnline } = useConnection();

  const navigation = useNavigate();

  const { data: coach } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });
  const { data: coachAppel } = useGetCoachAppelsQuery(
    { coachId: userId, schoolId },
    {
      refetchOnFocus: true,
    }
  );
  const [addAppel] = useAddAppelMutation();
  const { data: studentsData, isLoading } = useGetElevesQuery(
    { coachId: userId, schoolId },
    {
      refetchOnFocus: true,
    }
  );

  const [updateAppel] = useUpdateAppelMutation();

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = React.useState("eleves");
  // const [numberSend, setNumberSend] = React.useState(0);
  const [sendAppelLoading, setSendAppelLoading] = React.useState(false);

  const offlineData = useSelector(getAppelsOfflineData)?.filter(
    (item) => item?.schoolId === schoolId
  );

  /**
   * Aplitude
   */
  // if (coach?.id) {
  //   amplitude.getInstance().logEvent("session", {
  //     coach: {
  //       prenom_et_nom: coach?.fullName,
  //       telephone: coach?.phone,
  //     },
  //     page: "Appel",
  //   });
  // }

  /**
   *
   * @param {Object} student
   * @param {String} status
   */
  const handleCall = (student, status, index) => {
    const body = {
      student: {
        firstName: student?.firstName,
        lastName: student?.lastName,
        school: { name: student?.schoolId?.name },
        classe: { name: student?.classeId?.name },
      },
      studentId: student?.id,
      coachId: userId,
      schoolId,
      status,
    };

    dispatch(addAppelOffline(body));
  };

  const handleCallUpdate = async (student, status) => {
    try {
      await updateAppel({ id: student?.id, status });
      console.log("success");
    } catch (error) {
      console.log("error", error);
    }
  };

  // console.log(
  //   studentsData?.data.filter((student) => !student?.appel?.status).length
  // );

  const handleAddAppel = async () => {
    if (
      studentsData.data.filter((student) => !student?.appel?.status).length ===
      offlineData.length
    ) {
      setSendAppelLoading(true);

      const newAppelFormt = await offlineData.map((appel) => {
        return { studentId: appel?.studentId, status: appel?.status };
      });

      try {
        await addAppel({
          body: { schoolId, coachId: userId, appels: newAppelFormt },
          token: csrfToken,
        });

        if (coach?.id) {
          amplitude.track("Appel", {
            coach: {
              prenom_et_nom: coach?.fullName,
              telephone: coach?.phone,
            },
            nombreEleve: offlineData.length + "/" + studentsData.data.length,
            ecole: coach?.schools.find((ecole) => ecole?.id === schoolId)?.name,
          });
        }
        setSendAppelLoading(false);
        dispatch(clearAppelOffline());
      } catch (error) {
        console.log(error);
        return;
      }
    } else {
      alert("l'appel n'est pas acheve");
    }
  };

  return (
    <>
      <div style={{}} className={styles.appelTab}>
        <TabLink
          label={"Eleves"}
          Icon={FaUsers}
          style={{
            width: "50%",
            backgroundColor: bgColorPrimary,
            color: "#fff",
            borderBottom: `3px solid ${
              activeTab === "eleves" ? "#fff" : bgColorPrimary
            }`,
          }}
          onclick={() => setActiveTab("eleves")}
        />
        <TabLink
          label={"liste d'appel"}
          Icon={FaList}
          style={{
            width: "50%",
            backgroundColor: bgColorPrimary,
            color: "#fff",
            borderBottom: `3px solid ${
              activeTab !== "eleves" ? "#fff" : bgColorPrimary
            }`,
          }}
          onclick={() => setActiveTab("appels")}
        />
      </div>

      {activeTab === "eleves" ? (
        <Students
          data={studentsData?.data ?? []}
          handleCall={handleCall}
          handleCallUpdate={handleCallUpdate}
          isLoading={isLoading}
          offlineData={offlineData ?? []}
          navigation={navigation}
          isOnline={isOnline}
          onfinishedAppel={() => setActiveTab("appels")}
          onSendAppel={handleAddAppel}
          onAppelLoading={sendAppelLoading}
        />
      ) : (
        <AppelList
          data={coachAppel?.data[0] ? coachAppel?.data : offlineData ?? []}
          dispatch={dispatch}
          // number={numberSend}
          onSendAppel={handleAddAppel}
          onAppelLoading={sendAppelLoading}
          isOnline={isOnline}
          onlineData={coachAppel?.data[0] ? true : false}
        />
      )}
    </>
  );
}

export default Appel;
