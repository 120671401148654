const { createSlice } = require("@reduxjs/toolkit");

// Create connection slice
const connectionSlice = createSlice({
  name: "connection",
  initialState: {
    connection: false,
    access: false,
  },
  reducers: {
    setConnection: (state, { payload }) => {
      state.connection = payload;
    },
    setAccess: (state, { payload }) => {
      state.access = payload;
    },
  },
});

export const getConnectionStatus = (state) => state?.statusConnection;

// export const getAccess = (state) => state?.statusConnection?.access;

export const { setConnection, setAccess } = connectionSlice.actions;
export default connectionSlice.reducer;
