import React from "react";
import styles from "assets/styles.module.css";
import * as amplitude from "@amplitude/analytics-browser";
import SearchBarPage from "components/SearchBarPage";
import ScrollView from "components/ScrollView";
import useGetUserInfo from "hooks/useGetUserInfo";
import { useGetCoachFichesQuery } from "redux/features/ficheApi";
import { FaFile, FaSchool } from "react-icons/fa";
import Text from "components/Text";
import Divider from "components/Divider";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";
import { useGetElevesQuery } from "redux/features/eleveApi";
import { useGetCoachQuery } from "redux/features/coachApi";

function ListFiche() {
  const { userId, schoolId } = useGetUserInfo();

  const navigation = useNavigate();

  const { data: coach } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });

  const { data: fichesdata, isLoading } = useGetCoachFichesQuery(
    { coachId: userId, schoolId },
    {}
  );
  const { data: studentsData } = useGetElevesQuery(
    { coachId: userId, schoolId },
    {
      refetchOnFocus: true,
    }
  );

  const [search, onSearch] = React.useState("");

  // console.log({ data: fichesdata });

  return (
    <div
      className={styles.evaluationContainer}
      style={{ height: "100%", width: "100%" }}
    >
      <SearchBarPage onSearch={onSearch} />

      {isLoading ? (
        <Loader />
      ) : (
        <ScrollView style={{ backgroundColor: "transparent" }}>
          {fichesdata?.data.length > 0 &&
            fichesdata?.data
              ?.filter(
                (item) =>
                  item?.fiches?.materiel
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item?.fiches?.name
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              ?.map((item) => (
                <div
                  key={item?.fiches?.id}
                  className={styles.ficheContainer}
                  onClick={() => {
                    navigation(
                      `detailFiche/${item?.fiches?.materiel}/${item?.fiches?.objectifPedagogique}/${item?.fiches?.id}`
                    );
                    amplitude.track("Fiche", {
                      coach: {
                        prenom_et_nom: coach?.fullName,
                        telephone: coach?.phone,
                      },
                      fiche: item?.fiches?.name,
                    });
                    // console.log(item?.fiches?.name);
                  }}
                >
                  <div className={styles.row}>
                    <div className={styles.fichesTitle}>
                      <div className={styles.row}>
                        <FaFile size={12} style={{ marginRight: 7 }} />
                        <Text style={{ fontSize: 11 }}>
                          {item?.fiches?.name}
                        </Text>
                      </div>
                    </div>
                    <div className={styles.row}></div>
                  </div>

                  <Text style={{ fontWeight: 500 }}>
                    {" "}
                    {item?.fiches?.materiel}
                  </Text>
                  <Text style={{ fontWeight: 300, fontSize: 12, marginTop: 5 }}>
                    {" "}
                    {item?.fiches?.materiel}
                  </Text>

                  <Divider style={{ margin: "15px 0" }} />

                  <div className={styles.row}>
                    <FaSchool style={{ marginRight: 7 }} />
                    <Text style={{ fontSize: 13 }}>
                      {item?.fiches?.niveaux_list?.name}
                    </Text>
                  </div>

                  <div className={styles.row} style={{ marginTop: 2 }}>
                    {/* <Text>Classe : </Text> */}
                    <Text
                      style={{ fontWeight: 400, fontSize: 12, marginLeft: 22 }}
                    >
                      {item?.fiches?.classe?.name}
                    </Text>
                  </div>

                  {item?.evaluations && schoolId && (
                    <>
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: "12px",
                          marginTop: 5,
                          fontWeight: 500,
                        }}
                      >
                        {Math.floor(
                          ((studentsData?.data?.length -
                            item?.evaluations?.eleveNonCompris) *
                            100) /
                            studentsData?.data?.length
                        )}{" "}
                        %
                      </Text>
                      <div
                        style={{
                          width: "100%",
                          height: 5,
                          borderRadius: 10,
                          backgroundColor: "rgba(0,0,0,0.2)",

                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        {/* <Text>{item?.evaluations?.eleveNonCompris}</Text> */}
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            height: "100%",
                            backgroundColor: "green",
                            width: `${Math.floor(
                              ((studentsData?.data?.length -
                                item?.evaluations?.eleveNonCompris) *
                                100) /
                                studentsData?.data?.length
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </>
                  )}
                </div>
              ))}
        </ScrollView>
      )}
    </div>
  );
}

export default ListFiche;
