import Appel from "pages/appel/Appel";
import AppelContainer from "pages/appel/AppelContainer";
import StudentDetails from "pages/appel/StudentDetails";
import ForgetPassword from "pages/auth/ForgetPassword";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import CorpusContainer from "pages/corpus/CorpusCaontainer";
import CorpusNouveaux from "pages/corpus/CorpusNouveaux";
import CorpusPage from "pages/corpus/CorpusPage";
import Evaluation from "pages/evaluation/Evaluation";
import DetailFiche from "pages/guide/DetailFiche";
import FicheEvaluation from "pages/guide/FicheEvaluation";
import Guide from "pages/guide/Guide";
import ListFiche from "pages/guide/ListFiches";
import Payement from "pages/payement/Payement";
import Profile from "pages/profile/Profile";
import School from "pages/school/School";
// import TestDePositionnementAcceuil from "pages/testDePositionnement/TestDePositionnementAcceuil";
// import TestDePositionnementDetail from "pages/testDePositionnement/TestDePositionnementDetail";
// import TestDePositionnementElevePageDesign from "pages/testDePositionnement/TestDePositionnementElevePageDesign";
// import TestDePositionnementNiveauPage from "pages/testDePositionnement/TestDePositionnementNiveauPage";
// import TestDePositionnementNiveauPageDesign from "pages/testDePositionnement/TestDePositionnementNiveauPageDesign";
// import TestDePositionnementPage from "pages/testDePositionnement/TestDePositionnementPage";
// import TestDePositionnementContainer from "pages/testDePositionnement/TestDepositionnementContainer";
import { Navigate } from "react-router-dom";

/**
 * Public routes
 */
export const publicRoutes = [
  {
    path: "*",
    element: <Navigate to={"/login"} />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/register/:phone",
    element: <Register />,
  },
];

/**
 * Private routes
 */
export const privateRoutes = [
  {
    path: "*",
    element: <Navigate to={"/school"} />,
  },
  {
    path: "/school",
    element: <School />,
  },
  {
    path: "/appel",
    element: <AppelContainer />,
    subPath: [
      { path: "", element: <Appel /> },
      { path: "student/:id/:status", element: <StudentDetails /> },
      { path: "payement/:id/:name", element: <Payement /> },
    ],
  },
  {
    path: "/guide",
    element: <Guide />,
    subPath: [
      { path: "", element: <ListFiche /> },
      {
        path: "detailFiche/:materiel/:objectifPedagogique/:id",
        element: <DetailFiche />,
      },
      {
        path: "ficheEvaluation/:materiel/:objectifPedagogique/:id",
        element: <FicheEvaluation />,
      },
    ],
  },
  {
    path: "/evaluation",
    element: <Evaluation />,
  },
  {
    path: "/corpus",
    element: <CorpusPage />,
  },
  {
    path: "/corpus",
    element: <CorpusContainer />,
    subPath: [
      { path: "", element: <CorpusPage /> },
      {
        path: "nouveaux",
        element: <CorpusNouveaux />,
      },
    ],
  },
  // {
  //   path: "/diagnostique",
  //   element: <TestDePositionnementContainer />,
  //   subPath: [
  //     { path: "", element: <TestDePositionnementAcceuil /> },
  //     // { path: "", element: <TestDePositionnementNiveauPage /> },TestDePositionnementElevePageDesign
  //     { path: "eleves", element: <TestDePositionnementNiveauPageDesign /> },
  //     {
  //       path: "eleves/eleve",
  //       element: <TestDePositionnementElevePageDesign />,
  //     },
  //     {
  //       path: "eleves/eleve/:matiere",
  //       element: <TestDePositionnementDetail />,
  //     },
  //   ],
  // },
  {
    path: "/profile",
    element: <Profile />,
  },
];
