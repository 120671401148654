import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
// import appelOfflineReducer from "redux/features/AppelSlice";
import storage from "redux-persist/lib/storage";
import { baseApi } from "redux/features/apiMananger";
import userIdReducer from "redux/features/userSlice";
import connectionReducer from "redux/features/connectionSlice";


const persistConfig = {
  key: "genimiRoot",
  storage,
};

const rootReducer = combineReducers({
  // dataPersisting: persistReducer(persistConfig, appelOfflineReducer),
  user: persistReducer(persistConfig, userIdReducer),
  statusConnection: connectionReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([baseApi.middleware]);
  },
  devTools: true,
});

export const persistor = persistStore(store);
