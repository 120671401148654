import { ApiMananger } from "./apiMananger";

const paiementApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    addPaiement: build.mutation({
      query({ body, token }) {
        return {
          url: "/payements/",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
            // Referer: "https://genimi-admin.bakeli.tech/",
            // "Referrer-Policy": "strict-origin-when-cross-origin",
          },
          body,
        };
      },
      invalidatesTags: ["Paiements", "Eleves"],
    }),
  }),
});

export const { useAddPaiementMutation } = paiementApi;
