import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import Amplitude from "amplitude-js";
import reportWebVitals from "./reportWebVitals";

// Hotjar
// import Hotjar from "@hotjar/browser";

// Amplitude
import * as amplitude from "@amplitude/analytics-browser";

// let sessionStartTime = Date.now();

amplitude.init("1ad47ee2781c79965eb6eb3007d4fc49", undefined, {
  defaultTracking: {
    sessions: true,
    pageViews: true,
    formInteractions: true,
    fileDownloads: true,
  },
  heartbeatIntervalSeconds: 20,
});

// const siteId = 3504318;
// const hotjarVersion = 6;

// Hotjar.init(siteId, hotjarVersion);

// Enregistrer un événement de démarrage de session.
// amplitude.logEvent("session_utilisateur", { session_start_time: sessionStartTime });

// // Enregistrer un événement de ping toutes les X secondes.
// setInterval(() => {
//   amplitude.logEvent("session_utilisateur");
// }, 10000); // Envoi un événement de ping toutes les 10 secondes.

// Enregistrer un événement de fin de session.
// window.addEventListener("beforeunload", () => {
//   amplitude.logEvent("session_end", {
//     session_duration: Date.now() - sessionStartTime,
//   });
// });

// amplitude.getInstance().init("1ad47ee2781c79965eb6eb3007d4fc49", null, {
//   includeUtm: true,
//   includeReferrer: true,
//   trackingOptions: {
//     city: true,
//     region: true,
//     country: true,
//   },
// });

// const _amplitude = new Amplitude("1ad47ee2781c79965eb6eb3007d4fc49", {
//   tokenEndpoint: "https://analytics.amplitude.com/redteam",
// });

// console.log(_amplitude);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
