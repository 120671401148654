import React from "react";
import styles from "assets/styles.module.css";
import AppelCard from "components/AppelCard";
import AppelListHeader from "components/AppelListHeader";
import Text from "components/Text";
import { IconSend } from "@tabler/icons-react";
import { RotatingLines } from "react-loader-spinner";
import Empty from "components/Empty";

function AppelList(props) {
  const [active, setActive] = React.useState("Tout");
  const [search, onSearch] = React.useState("");

  return (
    <div
      className={styles.appelContent}
      style={{
        color: "rgba(0,0,0,0.7)",
        overflowY: "scroll",
        paddingBottom: 160,
      }}
    >
      <AppelListHeader
        active={active}
        onClick={(value) => setActive(value)}
        data={props.data}
        onSearch={onSearch}
      />

      {props.data.length > 0 ? (
        <div
          style={{
            padding: 10,
            color: "rgba(0,0,0,0.7)",
            marginBottom: 20,
          }}
        >
          {props.data
            ?.filter(
              (item) =>
                item?.student?.firstName
                  ?.toLowerCase()
                  ?.includes(search?.toLowerCase()) ||
                item?.student?.lastName
                  ?.toLowerCase()
                  ?.includes(search?.toLowerCase())
            )
            ?.filter((item) =>
              active === "Tout"
                ? item
                : active === "Absent"
                ? item.status?.includes("ABSENT")
                : item.status?.includes("PRESENT") ||
                  (item.status?.includes("RETARD") && item)
            )
            ?.map((item, key) => (
              <AppelCard
                key={key}
                {...item}
                dispatch={props.dispatch}
                onlineData={props.onlineData}
              />
            ))}

          {!props.onlineData && (
            <button
              className={styles.btnFloat}
              onClick={props.onSendAppel}
              disabled={props.onAppelLoading}
            >
              <Text style={{ fontSize: 13, marginRight: 10 }}>
                {props.onAppelLoading
                  ? "Envoie en cours ..."
                  : " Envoyer l'appel"}
              </Text>
              {props.onAppelLoading ? (
                <RotatingLines
                  strokeColor="#fff"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="18"
                  visible={true}
                />
              ) : (
                <IconSend size={18} />
              )}
            </button>
          )}
        </div>
      ) : (
        <Empty title={"La liste d'appel est vide"} />
      )}
    </div>
  );
}

export default AppelList;
