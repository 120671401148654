import StudentCard from "components/StudentCard";
import styles from "assets/styles.module.css";
import React from "react";
import Loader from "components/Loader";
import Empty from "components/Empty";
import Offline from "components/Offline";
// import { TabsContext } from "context/TabContext";

function Students(props) {
  const ref = React.useRef(null);

  // const [index, setIndex] = React.useState(0);
  const [currentItem, setCurrentItem] = React.useState(0);

  // const { updateActiveTab } = React.useContext(TabsContext);

  React.useEffect(() => {
    ref.current?.children[currentItem]?.scrollIntoView({ behavior: "smooth" });
  }, [currentItem]);


  return props.isLoading ? (
    <Loader />
  ) : (
    <div
      className={styles.appelContent}
      style={{
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto",
      }}
      ref={ref}
    >
      {props?.data && props?.data.length > 0 ? (
        [
          ...props?.data,
          props.offlineData.length > 0 && {
            status: "end",
            id: "zzzzzzzzzzzzzzzzzzzzz",
          },
        ]?.map(
          (student, index) =>
            student.id && (
              <StudentCard
                key={student?.id}
                index={index}
                width={window?.innerWidth}
                onCall={(status) => {
                  // setIndex((v) =>
                  //   v === ref.current?.children.length ? 0 : v + 1
                  // );
                  props?.handleCall(student, status);
                  setCurrentItem(index + 1)
                }}
                onCallUpdate={(status) =>  props?.handleCallUpdate(student, status)}
                appelOffline={props?.offlineData}
                navigation={props?.navigation}
                // onfinishedAppel={() => updateActiveTab(1)}
                onfinishedAppel={props.onfinishedAppel}
                handleAddAppel={props.onSendAppel}
                onAppelLoading={props.onAppelLoading}
                {...student}
              />
            )
        )
      ) : !props?.isOnline ? (
        <Offline />
      ) : (
        <Empty title={"Vous n'avez pas d'eleve"} />
      )}
    </div>
  );
}

export default Students;
