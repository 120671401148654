import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "redux/app/Store";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "context/AuthContext";
import Root from "Root";
import { BrowserRouter } from "react-router-dom";
import { DrawerProvider } from "context/DrawerContext";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <DrawerProvider>
              <div className="App">
                <header className="App-header">
                  <Root />
                </header>
              </div>
            </DrawerProvider>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
